let artTable = {

	changeSort: (e, tid, fName) => {
		let elSort = e.A('sort');
		let sD = elSort
				? (
					elSort === 'DESC'
							? 'ASC'
							: (
								elSort === 'ASC' ? '' : 'DESC'
							)
				)
				: 'DESC';
		// console.log("changeFilter", e, elSort, 'sD', sD);
		let dl = I(tid);
		let url = dl.A('ref-src').setURLParamEncode(`flagSort[${fName}]`, sD);
		dl.setA('ref-src', url);
		return App.func.reloadEl(dl);
	},

	onKeyEnterChangeFilter: (e, tid) => {
		if (!e) e = window.event;
		let keyCode = e.keyCode || e.which;
		if (keyCode == '13') {
			return artTable.changeFilter(e.target, tid);
		}
	},

	changeFilter: (e, tid) => {
		let elName = e.A('name');
		if (!elName) {
			alert("Undefined attribute 'name'");
			return;
		}
		// console.log("changeFilter", e, elName, e.value);
		let dl = I(tid);
		let url = dl.A('ref-src').setURLParamEncode(`flagFilter[${elName}]`, e.value);
		url = url.setURLParam(`offset`, 0);
		dl.setA('ref-src', url);
		return App.func.reloadEl(dl);
	},

	clearFilter: (tid, fid, val = '') => {
		// if (val === '') return;
		let el = I(fid);
		// console.log('el', el);
		if (!el) {
			alert("Undefined el by id " + fid);
			return;
		}
		el.value = '';
		let elName = el.A('name');
		if (!elName) {
			alert("Undefined attribute 'name'");
			return;
		}
		let dl = I(tid);
		let url = dl.A('ref-src');
		let prevVal = url.URLParamEncode(`flagFilter[${elName}]`);

		if (prevVal === undefined || prevVal===val) return;
		url = url.setURLParamEncode(`flagFilter[${elName}]`, val);
		url = url.setURLParam(`offset`, 0);
		dl.setA('ref-src', url);

		return App.func.reloadEl(dl);
	},

	toggleFilter: (e,tid) => {
		let dl = I(tid);

		let url = dl.A('ref-src');
		let val = (url.URLParam(`filterActive`, false) === 'true') ? false : true;

		let filterRow = $('.filterRow', dl);
		if (val) filterRow.addClass('hidden');
		else filterRow.removeClass('hidden');

		url = url.setURLParam(`filterActive`, val);
		url = url.setURLParam(`offset`, 0);
		dl.setA('ref-src', url);
		return App.func.reloadEl(dl);
	},

	setLimit: (selObj, tid) => {
		// console.log("setLimit", selObj);
		let dl = I(tid);
		let url = dl.A('ref-src').setURLParam('limit', selObj.value);
		url = url.setURLParam(`offset`, 0);
		dl.setA('ref-src', url);
		return App.func.reloadEl(dl);
	},

	goToPage: (tid, val) => {
		let dl = I(tid);
		let url = dl.A('ref-src').setURLParam('offset', val);
		dl.setA('ref-src', url);
		return App.func.reloadEl(dl);
	},

	toggleRefElData: (el, data) => {
		if (typeof el === "string") {
			el = I(el);
		}
		let $el = $(el);
		// console.log("toggleHistoryData", $el.children());
		if ($el.children().length) {
			$el.empty();
			return Promise.resolve();
		} else {
			return App.func.reloadElementWithData(el, data);
		}
	}
};

window.artTable = artTable;